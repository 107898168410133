 import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { MttStatus } from '../../models/enums/Mtt';
import { SupervisedUserCircle } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import { palette } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import PriorityHighSharpIcon from '@mui/icons-material/PriorityHighSharp';
import Tooltip from '@mui/material/Tooltip';
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import ModeCommentSharpIcon from '@mui/icons-material/ModeCommentSharp';
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp';
import { globalSelector } from "../../store/global/globalReducer";
import { useSelector } from 'react-redux';

interface IMttButton {
    buttonTitle: string;
    buttonText?:string;
    mttStatus: MttStatus;
    isDisabled?:boolean;
    menuPrimaryFunction: () => void;
}
 
export const MttButton = ( {buttonTitle, buttonText, mttStatus, isDisabled, menuPrimaryFunction }:IMttButton) => {
   
    const [mttState, setMttState] = React.useState({
      badgeContent:<></>, badgeColor:'', badgeVisible:false, toolTipText:'', iconColor:palette['primary'], buttonDisabled: isDisabled, loadingElibility: false
    });
    const notActiveButtonState = {badgeContent:<></> , badgeColor:'',  badgeVisible:false, toolTipText:'Student is not in an Intervention', iconColor:'info', buttonDisabled: isDisabled, loadingElibility:false};
    const { termIsEditable } = useSelector(globalSelector);
    
    useEffect(() => {
 
      if (!termIsEditable) {
        setMttState(notActiveButtonState);
      } else {
        switch(mttStatus) {
          case MttStatus.ActiveMtt:        
            setMttState({
              badgeContent:<></>, 
              badgeColor:'', 
              badgeVisible:false,
              toolTipText:'Student is in an active Intervention', 
              iconColor:'primary', 
              buttonDisabled: isDisabled,
              loadingElibility:false
            });
            break;
          case MttStatus.ActiveWCommentsMtt:        
            setMttState({
              badgeContent:<ModeCommentSharpIcon style={{color:'#fff'}} />, 
              badgeColor:'#0077EE', 
              badgeVisible:true,
              toolTipText:'Student has a new comment on an Intervention', 
              iconColor:'primary', 
              buttonDisabled: isDisabled, 
              loadingElibility:false
            });
            break;
          case MttStatus.EligibleMtt:
            setMttState({
              badgeContent:<PriorityHighSharpIcon style={{color:'#fff'}}/>, 
              badgeColor:'#C62828', 
              badgeVisible:true,
              toolTipText:'Student is eligible for an Intervention', 
              iconColor:'primary', 
              buttonDisabled: isDisabled, 
              loadingElibility:false
            });
            break;
          case MttStatus.EligibleToExit:
            setMttState({
              badgeContent:<ExitToAppSharpIcon style={{color:'#fff'}}/>, 
              badgeColor:'#C99700', 
              badgeVisible:true,
              toolTipText:'Student is eligible to be exited out of the current Intervention', 
              iconColor:'primary', 
              buttonDisabled: isDisabled, 
              loadingElibility:false
            });
            break;
          case MttStatus.ExitedDroppedMtt:
            setMttState({
              badgeContent:<ArrowDownwardSharpIcon style={{color:'#fff'}}/>, 
              badgeColor:'#9E9E9F', 
              badgeVisible:true, 
              toolTipText:'Student has dropped', 
              iconColor:'info', 
              buttonDisabled: isDisabled, 
              loadingElibility:false
            });
            break;
          case MttStatus.NotEligible:        
            setMttState({
              badgeContent:<></>, 
              badgeColor:'', 
              badgeVisible:false,
              toolTipText:'Student is not eligible for MTT Intervention', 
              iconColor:'info', 
              buttonDisabled: isDisabled,
              loadingElibility:false
            });
            break;
          case MttStatus.LoadingEligiblity:
            setMttState({
              badgeContent:<></>, 
              badgeColor:'', 
              badgeVisible:false,
              toolTipText:'Loading', 
              iconColor:'info', 
              buttonDisabled: true, 
              loadingElibility:true
            });
            break;    
          default:
            setMttState(notActiveButtonState);
          break;
        }
      }
      
    }, [mttStatus, isDisabled, termIsEditable]);

    return buttonText ? (
        // Mtt Icons Button with Text
        <Button
          color='secondary'
          variant="contained"
          id={`button-${buttonTitle}`}
          onClick={() => menuPrimaryFunction()}
          style={{justifyContent:'start', minWidth: '200px', textAlign: 'left'}}
          startIcon={
            <Badge badgeContent={mttState.badgeContent} invisible={!mttState.badgeVisible}
                sx={{
                  '.MuiBadge-badge':{
                    color: '#fff',
                    backgroundColor: mttState.badgeColor,
                  }
                }}
              >
              <SupervisedUserCircle sx={{ fontSize: 30 }}  color={mttState.iconColor}/>
            </Badge>
          }
        >
          {buttonText ? buttonText : ''}  
        </Button>
      ) : (
        // Mtt Icons Button
        
          <Button
            color='secondary'
            variant="contained"
            id={`button-${buttonTitle}`}
            onClick={() => menuPrimaryFunction()}
            style={{padding:'3px'}}
            disabled={mttState.buttonDisabled}
          >   
            <Tooltip title={mttState.toolTipText} placement="right" enterDelay={1000}>
              <Badge badgeContent={mttState.badgeContent} invisible={!mttState.badgeVisible}
                  sx={{
                    '.MuiBadge-badge':{
                      color: '#fff',
                      backgroundColor: mttState.badgeColor,
                    }
                  }}
                >
                {!mttState.loadingElibility ? 
                  (<SupervisedUserCircle style={{ opacity: mttStatus === MttStatus.NotEligible ? '.7' : '1'}} sx={{ fontSize: 30 }}  color={mttState.iconColor}/>) 
                  : 
                  (<CircularProgress size={19} color="inherit" sx={{margin:'6px'}} />)
                }
              </Badge>
            </Tooltip> 
          </Button>
        
      )
  };